@import url('https://fonts.cdnfonts.com/css/lato');
@import url('https://use.fontawesome.com/releases/v5.8.1/css/all.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');
@import url('https://pro.fontawesome.com/releases/v5.10.0/css/all.css');


@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Lato', sans-serif;
  /* @apply scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100 */
}

.header {
  box-shadow: 0px 4px 8px rgba(255, 0, 184, 0.4);
  position: relative;
}

@media (max-width: 767px) {
  .header {
    box-shadow: none;
    border-bottom: none;
  }
}

h1 {
  font-size: 48px;
  font-weight: 700;
}

h3 {
  font-size: 20px;
  font-weight: 700;
}

p {
  font-size: 24px;
}

span {
  font-size: 18px;
}

@media (max-width: 1023px) {
  h1 {
    font-size: 44px;
  }

  h3 {
    font-size: 18px;
  }

  p {
    font-size: 20px;
  }

  span {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  h1 {
    font-size: 20px;
  }

  h3 {
    font-size: 18px;
  }

  p {
    font-size: 16px;
  }

  span {
    font-size: 12px;
  }
}

.btn,
.get-started-button {
  background: linear-gradient(90deg, #FF00B8 -4.21%, #6905B8 100%);
}

.btn-secondary {
  background: linear-gradient(#110942, #110942) padding-box, linear-gradient(90deg, #FF00B8 -4.21%, #6905B8 100%) border-box;
  border-radius: 14px;
  border: 4px solid transparent;
}

.polygon-bg {
  background-image: url(./assets/images/polygon1.png);
  background-repeat: no-repeat;
}

#suspense .loader,
#suspense .loader:before,
#suspense .loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

#suspense .loader {
  color: #ffffff;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

#suspense .loader:before,
#suspense .loader:after {
  content: '';
  position: absolute;
  top: 0;
}

#suspense .loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

#suspense .loader:after {
  left: 3.5em;
}

input {
  color: #000;
  border: solid 1px #fff;
  outline: none;
}

input.bg-transparent {
  color: #fff;
}

input.transparent .bg-element,.bg-element {
  background-image: url(assets/images/polygon1.png);
  background-repeat: no-repeat;
}
.bg-color-secondary{
  background-color: #FF00B8;
}
.bg-blue {
    background-color: #1C173A;
}
.bg-lightblue {
    background-color: #110942;
}
@-webkit-keyframes load7 {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

html {
  overflow: inherit !important;
  padding-right: 0 !important;
}